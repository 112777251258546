import React from 'react';
import { Hidden } from '@mui/material';
import DesktopNavbar from './navbar/DesktopNavbar';
import MobileNavbar from './navbar/MobileNavbar';

const LucilaNavbar = () => (
  <>
    <Hidden mdUp>
      <MobileNavbar />
    </Hidden>
    <Hidden mdDown>
      <DesktopNavbar />
    </Hidden>
  </>
);

export default LucilaNavbar;
